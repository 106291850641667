import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import { removeNumberPrefix } from '../../../../../utilities'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Hypoteka extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter


    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Hypotéka'}
          description={'Prípadová štúdia'}
          text={'Firemná identita a dizajn pre portál, pomahajúci ľuďom získať viac informácií o dostupných hypotékach na trhu na Slovenskom trhu bez zbytočného hľadanie na googli.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'Logo' },
            { text: 'Vytvorenie responzívnej HTML šablony' },
            { text: 'Programovanie', link: '/nase-prace/webstranky/firemne/hypotekask' },
          ]} />

        <AboutProject
          text={'Spolupráca s Hypotéka prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            },
          ]}
        />

        <ProjectShortDescription
          title={'Firemná identita'}
          description={'Pochopenie projektu, jeho zámeru a odborný prístup sú úspechom k plnohodnotnej firemnej identite.'}
          text={'Po konzultáciach s klientom sme pripravili jednoduché firemné princípy, ktoré by mal nový portál prezentovať nie len dizajnom ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#067eed', tintColorCode: '#02569e', nameColor: '#FFFFFF', name: 'Azure' },
          { code: '#343f49', tintColorCode: '#585f66', nameColor: '#FFFFFF', name: 'Charcoal' },
          { code: '#6b7b8a', tintColorCode: '#a6b0b9', nameColor: '#FFFFFF', name: 'Slate gray' },
          { code: '#c4cad0', tintColorCode: '#e1e5e8', nameColor: '#6B7B8A', name: 'Silver' },
        ]} />

        <FontShowcase
          fontName={'Asap'}
          text={'Asap is a contemporary sans-serif family with subtle rounded corners. Designed by Pablo Cosgaya, Asap ("as soon as possible") there are 4 styles: Regular, Italic, Bold and Bold Italic. This family, specially developed for screen and desktop use, offers a standarised character width on all styles, which means lines of text remain the same length. This useful feature allows users to change type styles on-the-go without reflowing a text body. Asap is based on Ancha (designed by Pablo Cosgaya and Hector Gatti), and has been developed with the collaboration of Andrés Torresi.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/asap.svg'}
        />

        <Reveal>
          <div className={'container mt-5 mb-5'}>
            <div className={'row'}>
              <div className={'col-md-5'}>
                <h2 className={'mb-3'}>Ilustrácie</h2>
                <p style={{ fontSize: '1.1rem' }}>Počas designových sprintov sme sa rozhodli prezentovať niektoré sekcie jednoduchými ilustraciámi, ktoré by mali apelovať na mladú generáciu mileniálov, ktorý si práve hľadajú vlastné ubytovanie prípadne zakladajú rodinky s deťmi. Po diskusií s klientom sme z ilustrácií upustili a použili fotografie, ktoré taktiež reprezentujú firemné princípy ako dôvera, rodina a odbornosť.</p>
              </div>
              <div className={'col-md-7'}>
                <Img alt={this.constructor.name} fluid={data.ilustration.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </Reveal>

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Hypotéka zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/firemne/hypotekask'}
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))} />
        
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="mobilnetelefony" />
          </div>
        </Reveal>

        {/*
        <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />
*/}
        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.hypoteka.sk" target='_blank' className='link-secondary'>www.hypoteka.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          nextProjectName={'Sangre Azul'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/firemne/sangre-azul/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/hypoteka/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Hypoteka_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/hypoteka/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Hypoteka_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    ilustration: file(relativePath: { eq: "case-studies/Hypoteka_ilustrations.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Hypoteka
